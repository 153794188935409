import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/naturalis/naturalis-main.jpg" imageAlt="A naturalis page" mdxType="TextMediaSplit">
      <h1>{`Naturalis`}</h1>
      <p>{`Naturalis has been created from the perspicuous shapes of extinct and endangered animals. This distinct font aims to bring life back to these creatures through its playful curvature and versatility.`}</p>
      <p>{`This 12 week project started with a visit to the Oxford Natural History museum where I collected photographs of specimens that interested me. After the trip I decided to highlight how many animals are either extinct or on the brink through the creation of this font and subsequent type specimen book.`}</p>
      <p>{`This hand-bound type-specimen book consists of 9 glyphs, showcased in a variety of forms, each bookmarked with a lasercut page inspired by the texture of the corresponding specimen.`}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/naturalis/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      